import { StillProcessingReceipt } from "./routes/Receipts";
import { Card, CardBody } from "@nextui-org/react";
import {
  ArrowPathIcon,
  DocumentArrowDownIcon,
} from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { ampli } from "./ampli";

export function StillProcessingReceiptRow({
  receipt,
}: {
  receipt: StillProcessingReceipt;
}) {
  return (
    <div className="my-4">
      <Card>
        <CardBody className="flex-row justify-between items-center">
          <div className="">
            <ArrowPathIcon className="size-6 inline-block align-bottom mr-2" />
            Your receipt is processing
          </div>
          <NavLink
            onClick={() => {
              ampli.stillProcessingReceiptImageViewed({
                imageId: receipt.imageId,
              });
            }}
            className="text-primary"
            to={`https://recibo-uploaded-receipts.s3.eu-west-2.amazonaws.com/${receipt.imageId}.jpg`}
          >
            <DocumentArrowDownIcon className="size-6 inline-block" />
          </NavLink>
        </CardBody>
      </Card>
    </div>
  );
}
