import { NavLink, useLoaderData, useParams } from "react-router-dom";
import { getApiHost } from "../getApiHost";
import { ReceiptRow } from "../ReceiptRow";
import { ampli } from "../ampli";
import { useEffect } from "react";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import { Card, CardHeader, CardBody, Divider } from "@nextui-org/react";

type Response =
  | {
      vendorName: string;
      totalInCents: number;
      purchasedAt: string;
      imageId: string;
      lineItems: ReceiptLineItem[];
    }
  | undefined;

export type ReceiptLineItem = {
  lineItemId: string;
  lineItemName: string;
  quantity: number;
  totalInCents: number;
  barcode: string;
  lineItemDescriptiveName: string;
};

export const loader =
  (token: string | undefined) =>
  async ({ params, request }: { params: any; request: any }) => {
    const response = await fetch(
      `${getApiHost()}/receipt/${params.receiptId}/line_items`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      const data: Response = await response.json();
      return data;
    }
    return undefined;
  };

export const action =
  (token: string | undefined) =>
  async ({ params, request }: { params: any; request: any }) => {
    const formData = await request.formData();
    const lineItemId = formData.get("lineItemId") as string;
    try {
      const response = await fetch(
        `${getApiHost()}/delete_barcode_by_line_item_id`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            lineItemId,
          }),
        }
      );
      if (response.status === 200) {
        console.log("Successfully removed barcode");
      } else {
        console.error("Failed to remove barcode");
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

export function Receipt() {
  const { receiptId } = useParams();

  useEffect(() => {
    if (receiptId) {
      ampli.receiptPageViewed({
        receiptId,
      });
    }
  }, []);

  const loaderData = useLoaderData() as Response;

  if (!receiptId) {
    return null;
  }

  if (!loaderData) {
    return null;
  }

  const { vendorName, totalInCents, purchasedAt, imageId, lineItems } =
    loaderData;

  const date = new Date(purchasedAt);
  const formatter = new Intl.DateTimeFormat("en-UK", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const formattedDate = formatter.format(date);

  return (
    <div>
      <Card className="my-4">
        <CardHeader className="justify-between pb-0">
          <div className="font-extralight text-sm">{formattedDate}</div>
          <div>
            {(totalInCents / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "GBP",
            })}
          </div>
        </CardHeader>
        <CardBody className="flex-row justify-between items-center">
          <div>{vendorName}</div>
          <NavLink
            className="text-primary"
            onClick={() => {
              ampli.processedReceiptImageViewed({
                receiptId: receiptId,
              });
            }}
            to={`https://recibo-uploaded-receipts.s3.eu-west-2.amazonaws.com/${imageId}.jpg`}
          >
            <DocumentArrowDownIcon className="size-6 inline-block ml-2" />
          </NavLink>
        </CardBody>
      </Card>
      <Divider className="my-2" />
      {lineItems.map((lineItem, index) => (
        <ReceiptRow key={index} lineItem={lineItem} />
      ))}
    </div>
  );
}
