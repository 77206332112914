import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./Auth";
import { Header } from "./Header";

const MAIN_CONTENT_ID = "main-content";

export function UnAuthRoot() {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="/home" />;
  }

  return (
    <div className="flex flex-col h-full">
      <div>
        <Header showUploadReceipt={false} showSearchByBarcode={false} />
      </div>
      <div id={MAIN_CONTENT_ID} className="flex-1 overflow-auto pt-2">
        <Outlet />
      </div>
    </div>
  );
}
