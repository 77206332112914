import { NavLink } from "react-router-dom";
import { Card, CardBody, CardHeader } from "@nextui-org/react";
import { ProcessedReceipt } from "./routes/Receipts";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import { ampli } from "./ampli";

export function ProcessedReceiptRow({
  receipt,
}: {
  receipt: ProcessedReceipt;
}) {
  const date = new Date(receipt.purchasedAt);
  const formatter = new Intl.DateTimeFormat("en-UK", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const formattedDate = formatter.format(date);

  return (
    <div className="my-4">
      <Card>
        <CardHeader className="justify-between">
          <div className="font-extralight text-sm">{formattedDate}</div>
          <div>
            {(receipt.totalInCents / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "GBP",
            })}
          </div>
        </CardHeader>
        <CardBody className="text-primary flex-row justify-between items-center">
          <NavLink to={`/receipt/${receipt.receiptId}`}>
            {receipt.vendorName}
          </NavLink>
          <NavLink
            onClick={() => {
              ampli.processedReceiptImageViewed({
                receiptId: receipt.receiptId,
              });
            }}
            to={`https://recibo-uploaded-receipts.s3.eu-west-2.amazonaws.com/${receipt.imageId}.jpg`}
          >
            <DocumentArrowDownIcon className="size-6 inline-block ml-2" />
          </NavLink>
        </CardBody>
      </Card>
    </div>
  );
}
