import { ButtonProps, Button as NextUIButton } from "@nextui-org/button";

type RequiredProps = Required<
  Pick<ButtonProps, "type" | "size" | "color"> & {
    text: string;
  }
>;

type OptionalProps = Pick<ButtonProps, "name" | "value" | "className">;

type Props = RequiredProps & OptionalProps;

export function Button({
  text,
  type,
  name,
  value,
  size,
  color,
  className,
}: Props) {
  return (
    <NextUIButton
      size={size}
      type={type}
      name={name}
      value={value}
      color={color}
      className={className}
    >
      {text}
    </NextUIButton>
  );
}
