import { CameraIcon } from "@heroicons/react/24/outline";
import { useAuth } from "./Auth";
import { useNavigate } from "react-router-dom";
import { getApiHost } from "./getApiHost";
import { useState } from "react";
import { ampli } from "./ampli";
import { Spinner } from "@nextui-org/react";

export function UploadReceipt() {
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();
  const navigate = useNavigate();

  function upload(file: File) {
    const formData = new FormData();
    formData.append("receipt", file);
    setLoading(true);
    fetch(`${getApiHost()}/receipt/upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then(() => {
        ampli.uploadReceiptSuccess();
        setLoading(false);
        navigate("/upload-success");
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
        alert("Upload failed");
      });
  }
  return (
    <div>
      {loading && <Spinner />}
      {!loading && (
        <label htmlFor="upload-receipt">
          <CameraIcon className="size-6" />
          <input
            id="upload-receipt"
            type="file"
            multiple={false}
            accept="image/*"
            style={{ display: "none" }}
            onClick={() => {
              ampli.uploadReceiptClicked();
            }}
            onChange={(e) => {
              const files = e.currentTarget.files;
              if (files === null) {
                return;
              }
              if (files.length === 0) {
                return;
              }
              const file = files[0];
              if (!file) {
                return;
              }
              upload(file);
            }}
          />
        </label>
      )}
    </div>
  );
}
