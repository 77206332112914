import { Card, CardBody, CardHeader } from "@nextui-org/react";
import { useLoaderData } from "react-router-dom";
import { getApiHost } from "./getApiHost";

export type PriceHistory = {
  priceAlertId: string;
  priceHistoryId: string;
  timestamp: string;
  totalPriceInCentsWithoutPromotion: number;
  totalPriceInCentsWithPromotion: number | null;
  promotionDescription: string | null;
};

export const loader =
  (token: string | undefined) =>
  async ({ params }: { params: any }) => {
    const response = await fetch(
      `${getApiHost()}/morrisons_price_history/${params.priceAlertId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { priceHistory } = await response.json();
    return priceHistory;
  };

export function PriceAlert() {
  const priceHistory = useLoaderData() as PriceHistory[];
  return (
    <div className="h-full">
      {priceHistory.map((price) => (
        <div className="my-4">
          <Card>
            <CardHeader>
              <div>
                {new Date(price.timestamp).toLocaleString("en-UK", {
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </div>
            </CardHeader>
            <CardBody>
              <div>
                {price.promotionDescription &&
                price.totalPriceInCentsWithPromotion ? (
                  <>
                    {(
                      price.totalPriceInCentsWithPromotion / 100
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "GBP",
                    })}{" "}
                    | {price.promotionDescription}
                  </>
                ) : (
                  <>
                    {(
                      price.totalPriceInCentsWithoutPromotion / 100
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "GBP",
                    })}
                  </>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      ))}
    </div>
  );
}
