import { useEffect, useRef, useState } from "react";
import { getApiHost } from "../getApiHost";
import { useLoaderData } from "react-router-dom";
import {
  CheckIcon,
  EyeIcon,
  EyeSlashIcon,
  MinusCircleIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

export const loader = (token: string | undefined) => async () => {
  const response = await fetch(`${getApiHost()}/all_line_items`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status === 200) {
    const data: { lineItemName: string }[] = await response.json();
    return data.map((e) => e.lineItemName);
  }
  return [];
};

export function ShoppingList() {
  const allLineItems = useLoaderData() as string[];

  const allItemsFromLocalStorage: {
    [name: string]: {
      quantity: number;
      checked: boolean;
    };
  } =
    localStorage.getItem("allItems") !== null
      ? JSON.parse(localStorage.getItem("allItems") as string)
      : {};

  const [allItems, setAllItems] = useState<{
    [name: string]: {
      quantity: number;
      checked: boolean;
    };
  }>(
    allLineItems.reduce(
      (acc, name) => ({
        ...acc,
        [name]: {
          quantity: allItemsFromLocalStorage[name]?.quantity || 0,
          checked: allItemsFromLocalStorage[name]?.checked || false,
        },
      }),
      {}
    )
  );

  const [isEditing, setIsEditing] = useState(false);

  const [showCustomItems, setShowCustomItems] = useState(false);

  const [customItems, setCustomItems] = useState(
    localStorage.getItem("customItems") ?? ""
  );

  const [search, setSearch] = useState("");

  const [filteredItems, setFilteredItems] = useState(allItems);

  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setFilteredItems(
      Object.fromEntries(
        Object.entries(allItems).filter(([name]) =>
          name.toLowerCase().includes(search.toLowerCase())
        )
      )
    );
  }, [allItems, search]);

  useEffect(() => {
    localStorage.setItem("allItems", JSON.stringify(allItems));
  }, [allItems]);

  useEffect(() => {
    localStorage.setItem("customItems", customItems);
  }, [customItems]);

  function onClearAll() {
    setCustomItems("");
    setAllItems(
      Object.keys(allItems).reduce(
        (acc, name) => ({
          ...acc,
          [name]: {
            quantity: 0,
            checked: false,
          },
        }),
        {}
      )
    );
  }

  return (
    <div className="p-3">
      <div className="text-center text-4xl p-5">Shopping List</div>
      <div className="text-center pb-3">
        <button
          className="bg-secondary text-white text-xl rounded h-10 w-40"
          onClick={onClearAll}
        >
          <TrashIcon className="size-6 inline align-text-bottom mr-1" /> Clear
        </button>
      </div>
      <div className="text-center pb-3">
        {!isEditing && (
          <button
            className="bg-[#2F75C1] text-white text-xl rounded h-10 w-40"
            onClick={() => setIsEditing(true)}
          >
            <PencilIcon className="size-6 inline align-text-bottom mr-1" /> Edit
          </button>
        )}
        {isEditing && (
          <button
            className="bg-[#2F75C1] text-white text-xl rounded h-10 w-40"
            onClick={() => setIsEditing(false)}
          >
            <CheckIcon className="size-6 inline align-text-bottom mr-1" /> Save
          </button>
        )}
      </div>
      <div className="text-center">
        <div className="pb-2">
          <button
            className="bg-[#0437F2] text-white text-xl rounded h-10 w-40"
            onClick={() => {
              setShowCustomItems(!showCustomItems);
            }}
          >
            {showCustomItems ? (
              <EyeSlashIcon className="size-6 inline align-text-bottom mr-1" />
            ) : (
              <EyeIcon className="size-6 inline align-text-bottom mr-1" />
            )}{" "}
            Notes
          </button>
        </div>
        {showCustomItems && (
          <textarea
            className="w-full border-2 border-[#26619c]"
            rows={5}
            value={customItems}
            onChange={(e) => setCustomItems(e.target.value)}
          />
        )}
      </div>
      {isEditing && (
        <div className="mt-2 p-5">
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search for an item"
              className="w-full border-2 p-2 rounded-md text-xl"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              onBlur={() => searchRef.current?.focus()}
              ref={searchRef}
            />
          </div>
          {Object.entries(filteredItems).map(
            ([name, { quantity, checked }]) => (
              <div key={name} className="text-xl mb-6">
                <div>
                  <button>
                    <MinusCircleIcon
                      className="size-7 inline align-bottom"
                      onClick={() => {
                        setAllItems({
                          ...allItems,
                          [name]: {
                            ...allItems[name],
                            quantity: Math.max(0, quantity - 1),
                            checked: false,
                          },
                        });
                      }}
                    />
                  </button>
                  <span className="px-2">{quantity}</span>
                  <button>
                    <PlusCircleIcon
                      className="size-7 inline align-bottom"
                      onClick={() => {
                        setAllItems({
                          ...allItems,
                          [name]: {
                            ...allItems[name],
                            quantity: quantity + 1,
                            checked: false,
                          },
                        });
                      }}
                    />
                  </button>
                </div>
                <div>
                  <span>{name}</span>
                </div>
              </div>
            )
          )}
        </div>
      )}
      {!isEditing && (
        <div className="mt-2 p-5">
          {Object.entries(allItems)
            .filter(([name, { quantity, checked }]) => quantity > 0)
            .map(([name, { quantity, checked }]) => (
              <div key={name} className="flex items-center mb-4">
                <input
                  id={name}
                  type="checkbox"
                  checked={checked}
                  className="w-6 h-6 text-blue-600 rounded"
                  onChange={(event) => {
                    setAllItems({
                      ...allItems,
                      [name]: {
                        ...allItems[name],
                        checked: (event.target as HTMLInputElement).checked,
                      },
                    });
                  }}
                />
                <label htmlFor={name} className="ms-2 text-xl">
                  {quantity} x {name}
                </label>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
