import { useCallback, useEffect, useState } from "react";
import { Form, useNavigate } from "react-router-dom";
import { getApiHost } from "../getApiHost";
import { LinkIcon, LinkSlashIcon } from "@heroicons/react/24/outline";
import ScanbotSDK from "scanbot-web-sdk";
import { BarcodeResult } from "scanbot-web-sdk/@types/model/barcode/barcode-result";
import { IBarcodeScannerHandle } from "scanbot-web-sdk/@types/interfaces/i-barcode-scanner-handle";
import { ampli } from "../ampli";

export function SearchByBarcode({ token }: { token: string | undefined }) {
  const navigate = useNavigate();

  const [allLineItems, setAllLineItems] = useState<
    {
      line_item_name: string;
      barcode: string;
    }[]
  >([]);
  const [barcode, setBarcode] = useState<string | undefined>(undefined);
  const [sdk, setSdk] = useState<ScanbotSDK | undefined>(undefined);
  const [search, setSearch] = useState("");
  const [filteredItems, setFilteredItems] = useState(allLineItems);
  const [networkRequestState, setNetworkRequestState] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");

  useEffect(() => {
    setFilteredItems(
      allLineItems.filter((lineItem) =>
        lineItem.line_item_name.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, allLineItems]);

  useEffect(() => {
    async function initSdk() {
      const sdk = await ScanbotSDK.initialize({
        licenseKey: "",
      });
      setSdk(sdk);
    }
    initSdk();
  }, []);

  useEffect(() => {
    let scanner: IBarcodeScannerHandle | undefined = undefined;
    async function scanBarcode() {
      scanner = await sdk?.createBarcodeScanner({
        containerId: "scanner",
        onBarcodesDetected: (result: BarcodeResult) => {
          scanner?.dispose();
          const text = result.barcodes[0].text;
          setBarcode(text);
        },
      });
    }
    scanBarcode();
    return () => {
      scanner?.dispose();
    };
  }, [token, navigate, sdk]);

  useEffect(() => {
    async function makeApiCall() {
      try {
        const response = await fetch(`${getApiHost()}/search/barcode`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            barcode,
          }),
        });
        if (response.status === 200) {
          navigate(`/line-item-by-barcode/${barcode}`);
        } else {
          const response = await fetch(`${getApiHost()}/all_line_items`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.status === 200) {
            const data: { line_item_name: string; barcode: string }[] =
              await response.json();
            setAllLineItems(data);
          }
          return [];
        }
      } catch (e) {
        alert("Error scanning barcode");
        console.error(e);
      }
    }
    if (barcode) {
      makeApiCall();
    }
  }, [barcode, token, navigate]);

  const makeApiCall = useCallback(
    async (lineItemName: string) => {
      try {
        setNetworkRequestState("loading");
        const response = await fetch(
          `${getApiHost()}/link_barcode_by_line_item_name`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              lineItemName,
              barcode,
            }),
          }
        );
        if (response.status === 200) {
          console.log("Successfully scanned barcode");
          setNetworkRequestState("success");
          navigate(`/line-item-by-barcode/${barcode}`);
        } else {
          setNetworkRequestState("error");
        }
      } catch (e) {
        console.error(e);
        setNetworkRequestState("error");
      }
    },
    [barcode, navigate, token]
  );
  if (allLineItems.length === 0) {
    return (
      <div
        id="scanner"
        style={{
          height: "100dvh",
          width: "100dvw",
        }}
      ></div>
    );
  }

  switch (networkRequestState) {
    case "idle":
      return (
        <div className="p-3">
          <div className="text-center text-4xl p-5">Barcode not found</div>
          <div className="mt-2 p-5">
            <div className="mb-4">
              <input
                type="text"
                placeholder="Search for an item"
                className="w-full border-2 p-2 rounded-md text-xl"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </div>
            {filteredItems.map((lineItem) => (
              <div key={lineItem.line_item_name} className="text-xl mb-4">
                <div>
                  {lineItem.barcode ? (
                    // For now, this does not do anything because it's unclear how we should re-render the component due to absence of a loader
                    <Form method="post" className="inline">
                      <input
                        type="hidden"
                        name="lineItemName"
                        value={lineItem.line_item_name}
                      />
                      <button type="submit">
                        <LinkSlashIcon className="size-6 inline mr-2 align-bottom" />
                      </button>
                    </Form>
                  ) : (
                    <LinkIcon
                      className="size-6 inline align-text-bottom"
                      onClick={() => {
                        makeApiCall(lineItem.line_item_name);
                      }}
                    />
                  )}
                </div>
                <div>{lineItem.line_item_name}</div>
              </div>
            ))}
          </div>
        </div>
      );
    case "loading":
      return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
        </div>
      );
    case "error":
      if (barcode) {
        ampli.unableToGenerateNameForBarcode({
          barcode,
        });
      }
      return (
        <div className="h-full p-5 mt-7">
          <div className="text-center text-3xl pt-4">Something went wrong</div>
          <div className="text-center text-2xl p-4">
            Please try a different barcode until we fix this.
          </div>
          <div className="text-center">
            <button
              className="bg-[#2F75C1] text-white rounded text-xl py-2 px-4"
              onClick={() => navigate("/home")}
            >
              Back to home
            </button>
          </div>
        </div>
      );
    case "success":
      return null;
  }
}
