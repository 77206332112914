import { NavLink } from "react-router-dom";
import { ReceiptLineItem } from "./routes/Receipt";
import { Card, CardHeader, Divider, CardBody } from "@nextui-org/react";

export function ReceiptRow({ lineItem }: { lineItem: ReceiptLineItem }) {
  const barcode = lineItem.barcode;
  const onClickTo = lineItem.barcode
    ? `/line-item-by-barcode/${barcode}`
    : `/line-item-by-name/${encodeURIComponent(lineItem.lineItemName)}`;
  return (
    <div className="my-4">
      <Card>
        <CardHeader>
          <div>
            {(lineItem.totalInCents / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "GBP",
            })}
          </div>
        </CardHeader>
        <Divider />
        <CardBody className="text-primary">
          <NavLink to={onClickTo} className="">
            {lineItem.quantity} {"X "}
            {lineItem.lineItemDescriptiveName || lineItem.lineItemName}
          </NavLink>
        </CardBody>
      </Card>
    </div>
  );
}
