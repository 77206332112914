import { NavLink } from "react-router-dom";
import { GetLineItemParsedResponse } from "./routes/Home";
import { Card, CardBody, CardHeader, Divider } from "@nextui-org/react";

export function HomeRow({
  lineItem,
}: {
  lineItem: GetLineItemParsedResponse["lineItems"][number];
}) {
  const barcode = lineItem.barcode;

  const onClickTo = barcode
    ? `/line-item-by-barcode/${barcode}`
    : `/line-item-by-name/${encodeURIComponent(lineItem.lineItemName)}`;

  return (
    <div className="my-4">
      <Card className="">
        <CardHeader>
          <div>
            {(lineItem.totalInCents / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "GBP",
            })}
          </div>
        </CardHeader>
        <Divider />
        <CardBody className="text-primary">
          <div>
            <NavLink to={onClickTo} className="">
              {lineItem.quantity} {"X "}
              {lineItem.lineItemDescriptiveName || lineItem.lineItemName}
            </NavLink>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
