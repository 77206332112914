import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { getApiHost } from "./getApiHost";
import { ampli } from "./ampli";

export type LoginFunction = (token: string) => Promise<void>;
export type LogoutFunction = () => void;

const AuthContext = createContext({
  user: undefined as { token: string; userId: string } | undefined,
  login: async (token: string) => {},
  logout: () => {},
});

function getInitialUsersState() {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : undefined;
}

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<
    { token: string; userId: string } | undefined
  >(getInitialUsersState);

  const value = useMemo(
    () => ({
      user,
      login: async (token: string) => {
        try {
          const response = await fetch(`${getApiHost()}/user/me`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.status === 200) {
            const data: { user_id: string } = await response.json();
            ampli.identify(data.user_id);
            const newUserValue = { token, userId: data.user_id };
            localStorage.setItem("user", JSON.stringify(newUserValue));
            setUser(newUserValue);
          }
        } catch (e) {
          console.error(e);
        }
      },
      logout: () => {
        localStorage.removeItem("user");
        setUser(undefined);
      },
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
