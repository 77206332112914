import { NavLink, useLoaderData } from "react-router-dom";
import { getApiHost } from "../getApiHost";
import { LineItemByNameRow } from "./LineItemByNameRow";
import { Button } from "../atoms/Button";

export type LineItemByNameData = {
  receiptId: string;
  lineItemId: string;
  lineItemName: string;
  vendorName: string;
  purchasedAt: string;
  quantity: number;
  unitPriceInCents: number;
};

export const loader =
  (token: string | undefined) =>
  async ({ params, request }: { params: any; request: any }) => {
    const response = await fetch(`${getApiHost()}/line_item_by_name`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        lineItemName: decodeURIComponent(params.lineItemName),
      }),
    });
    if (response.status === 200) {
      const data: LineItemByNameData[] = await response.json();
      return data;
    }
    return [];
  };

export function LineItemByName() {
  const lineItems = useLoaderData() as LineItemByNameData[];

  return (
    <div className="h-full">
      <div className="text-lg flex justify-between items-center gap-2 px-3">
        <span>{lineItems[0]?.lineItemName}</span>
        <span className="inline-block">
          <NavLink
            to={`/link-barcode-by-line-item-name/${encodeURIComponent(
              lineItems[0]?.lineItemName
            )}`}
          >
            <Button
              type={"button"}
              color={"default"}
              size={"sm"}
              text={"Add barcode to all"}
            />
          </NavLink>
        </span>
      </div>
      {lineItems.map((lineItem, index) => (
        <LineItemByNameRow key={index} lineItem={lineItem} />
      ))}
    </div>
  );
}
