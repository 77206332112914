import { Input } from "../atoms/Input";

export function EmailInput() {
  return (
    <Input
      type="email"
      name="email"
      label="Email"
      placeholder="Enter your email"
      className="pb-2"
    />
  );
}
