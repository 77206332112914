import { Link, NavLink } from "react-router-dom";
import { UploadReceipt } from "./UploadReceipt";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

export function Header({
  showUploadReceipt,
  showSearchByBarcode,
}: {
  showUploadReceipt: boolean;
  showSearchByBarcode: boolean;
}) {
  return (
    <div className="pb-4 flex justify-between">
      {/* {showSearchByBarcode ? (
        <span>
          <Link to="/search-by-barcode">
            <MagnifyingGlassIcon className="size-6" />
          </Link>
        </span>
      ) : null} */}
      <span className="text-lg font-bold">
        <Link to={showUploadReceipt ? "/home" : "/"}>recibo</Link>
      </span>
      <div className="flex">
        <span className="mr-3">
          <NavLink to={"/search"}>
            <MagnifyingGlassIcon className="size-6" />
          </NavLink>
        </span>
        <span>{showUploadReceipt ? <UploadReceipt /> : null}</span>
      </div>
    </div>
  );
}
