import { Button } from "../atoms/Button";

export function RegisterButton() {
  return (
    <Button
      text="Register"
      type="submit"
      name="intent"
      value="register"
      color="primary"
      size="lg"
      className="min-w-full"
    />
  );
}
