import { ActionFunctionArgs, Form } from "react-router-dom";
import { Button } from "../atoms/Button";
import { LogoutFunction } from "../Auth";

export const action =
  (logout: LogoutFunction) =>
  async ({ params, request }: ActionFunctionArgs) => {
    logout();
    return null;
  };

export function Profile() {
  return (
    <div className="flex justify-center items-center h-full">
      <Form method="post">
        <Button text="Logout" type="submit" color="danger" size="lg" />
      </Form>
    </div>
  );
}
