import { Input } from "../atoms/Input";

export function PasswordInput() {
  //TODO: Add eye icon: https://nextui.org/docs/components/input#password-input
  return (
    <Input
      type="password"
      name="password"
      label="Password"
      placeholder="Enter your password"
      className="pb-2"
    />
  );
}
