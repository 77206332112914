import { useLoaderData, useSearchParams } from "react-router-dom";
import { getApiHost } from "../getApiHost";
import { HomeRow } from "../HomeRow";
import { ampli } from "../ampli";
import { useEffect, useState } from "react";
import { DateRangePicker, Divider } from "@nextui-org/react";
import {
  getLocalTimeZone,
  parseDate,
  startOfMonth,
  today,
} from "@internationalized/date";

export type GetLineItemParsedResponse = {
  numberOfReceipts: number;
  totalInCentsAllReceipts: number;
  totalInCentsOfTopLineItems: number;
  uniqueLineItemsByNameCount: number;
  lineItems: {
    lineItemName: string;
    quantity: number;
    totalInCents: number;
    barcode: string | null;
    lineItemDescriptiveName: string | null;
  }[];
};

const limit = 30;

const LOCAL_STORAGE_FROM_KEY = "FROM_V2";
const LOCAL_STORAGE_TO_KEY = "TO_V2";

const TODAY = today(getLocalTimeZone());
const DEFAULT_FROM = startOfMonth(TODAY);
const DEFAULT_TO = TODAY;

export const loader =
  (token: string | undefined) =>
  async ({ params, request }: { params: any; request: any }) => {
    const from =
      new URL(request.url).searchParams.get(LOCAL_STORAGE_FROM_KEY) ??
      localStorage.getItem(LOCAL_STORAGE_FROM_KEY) ??
      DEFAULT_FROM.toString();
    const to =
      new URL(request.url).searchParams.get(LOCAL_STORAGE_TO_KEY) ??
      localStorage.getItem(LOCAL_STORAGE_TO_KEY) ??
      DEFAULT_TO.toString();
    if (!token) return [];
    const response = await fetch(
      `${getApiHost()}/line_items?&limit=${limit}&from=${from}&to=${to}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      const data: GetLineItemParsedResponse = await response.json();
      return { parsedResponse: data };
    }

    return undefined;
  };

export function Home() {
  useEffect(() => {
    ampli.homePageViewed();
  }, []);

  const { parsedResponse } = useLoaderData() as
    | { parsedResponse: GetLineItemParsedResponse }
    | { parsedResponse: undefined };

  const [searchParams, setSearchParams] = useSearchParams();

  // TODO: We might not need the useState here and can simply use searchParams as the source of truth
  const [datePickerValue, setDatePickerValue] = useState(() => {
    return {
      start: parseDate(
        localStorage.getItem(LOCAL_STORAGE_FROM_KEY) ?? DEFAULT_FROM.toString()
      ),
      end: parseDate(
        localStorage.getItem(LOCAL_STORAGE_TO_KEY) ?? DEFAULT_TO.toString()
      ),
    };
  });

  useEffect(() => {
    if (!datePickerValue) {
      setSearchParams(undefined);
      localStorage.removeItem(LOCAL_STORAGE_FROM_KEY);
      localStorage.removeItem(LOCAL_STORAGE_TO_KEY);
      return;
    }
    localStorage.setItem(
      LOCAL_STORAGE_FROM_KEY,
      datePickerValue.start.toString()
    );
    localStorage.setItem(LOCAL_STORAGE_TO_KEY, datePickerValue.end.toString());
    setSearchParams({
      from: datePickerValue.start.toString(),
      to: datePickerValue.end.toString(),
    });
  }, [datePickerValue, setSearchParams]);

  const errorMessage =
    parsedResponse?.numberOfReceipts === 0
      ? "Please upload your first receipt by clicking on the camera icon in the top-right."
      : "No receipts found for the selected month.";

  if (!parsedResponse) {
    return <div>Something went wrong. Please try again later.</div>;
  }

  return (
    <div className="h-full">
      {parsedResponse.numberOfReceipts !== 0 && (
        <div>
          <div className="flex justify-center">
            <DateRangePicker
              calendarWidth={300}
              className="max-w-xs text-center"
              labelPlacement="outside"
              label={`Top ${limit} items from`}
              value={datePickerValue}
              onChange={setDatePickerValue}
            />
          </div>
          <Divider className="my-4" />
          <div className="text-center">
            {`Total spent: £${(
              parsedResponse.totalInCentsAllReceipts / 100
            ).toFixed(2)}`}
          </div>
        </div>
      )}
      {!parsedResponse.lineItems.length ? (
        <div className="text-2xl p-5">{errorMessage}</div>
      ) : (
        <div>
          {parsedResponse.lineItems.map((lineItem, index) => (
            <HomeRow key={index} lineItem={lineItem} />
          ))}
        </div>
      )}
    </div>
  );
}
