import { useNavigate } from "react-router-dom";

export function UploadSuccess() {
  const navigate = useNavigate();

  return (
    <div className="h-full p-5 mt-7">
      <div className="text-3xl text-center text-green-600 p-2">
        Your receipt has been uploaded.
      </div>
      <div className="text-center text-xl p-4">
        You'll receive an email when processing is complete, usually within a
        few hours. Thank you!
      </div>
      <div className="text-center">
        <button
          className="bg-[#2F75C1] text-white rounded text-xl py-2 px-4"
          onClick={() => navigate("/receipts")}
        >
          Back to receipts
        </button>
      </div>
    </div>
  );
}
