import { Button } from "../atoms/Button";

export function LoginButton() {
  return (
    <Button
      text="Login"
      type="submit"
      name="intent"
      value="login"
      color="primary"
      size="lg"
      className="min-w-full"
    />
  );
}
