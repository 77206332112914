/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/recibo/recibo/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'recibo';

export const ApiKey: Record<Environment, string> = {
  recibo: 'd73a718089d09fb92a12a4bc8a9f34d0'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'web',
    versionId: '53666dd8-4b7e-4a27-b5ad-4406924dbefe'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface AppUpdateSuccessProperties {
  newVersion: string;
  previousVersion?: string;
}

export interface ProcessedReceiptImageViewedProperties {
  receiptId: string;
}

export interface ReceiptPageViewedProperties {
  receiptId: string;
}

export interface StillProcessingReceiptImageViewedProperties {
  imageId: string;
}

export interface UnableToGenerateNameForBarcodeProperties {
  barcode: string;
}

export interface UnsubscribeEmailFailedProperties {
  email: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 400, 404, 500 |
   */
  httpErrorCode: "400" | "404" | "500";
}

export interface UnsubscribeEmailSuccessProperties {
  email: string;
}

export class AppUpdateError implements BaseEvent {
  event_type = 'App Update Error';
}

export class AppUpdateSuccess implements BaseEvent {
  event_type = 'App Update Success';

  constructor(
    public event_properties: AppUpdateSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomePageViewed implements BaseEvent {
  event_type = 'Home Page Viewed';
}

export class LandingPageCtaClicked implements BaseEvent {
  event_type = 'Landing Page CTA Clicked';
}

export class LandingPageViewed implements BaseEvent {
  event_type = 'Landing Page Viewed';
}

export class LoginFailed implements BaseEvent {
  event_type = 'Login Failed';
}

export class LoginPageViewed implements BaseEvent {
  event_type = 'Login Page Viewed';
}

export class LoginSuccess implements BaseEvent {
  event_type = 'Login Success';
}

export class ProcessedReceiptImageViewed implements BaseEvent {
  event_type = 'Processed Receipt Image Viewed';

  constructor(
    public event_properties: ProcessedReceiptImageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReceiptPageViewed implements BaseEvent {
  event_type = 'Receipt Page Viewed';

  constructor(
    public event_properties: ReceiptPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReceiptsPageViewed implements BaseEvent {
  event_type = 'Receipts Page Viewed';
}

export class RegisterFailed implements BaseEvent {
  event_type = 'Register Failed';
}

export class RegisterPageViewed implements BaseEvent {
  event_type = 'Register Page Viewed';
}

export class RegisterSuccess implements BaseEvent {
  event_type = 'Register Success';
}

export class StillProcessingReceiptImageViewed implements BaseEvent {
  event_type = 'Still Processing Receipt Image Viewed';

  constructor(
    public event_properties: StillProcessingReceiptImageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UnableToGenerateNameForBarcode implements BaseEvent {
  event_type = 'Unable To Generate Name For Barcode';

  constructor(
    public event_properties: UnableToGenerateNameForBarcodeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UnsubscribeEmailFailed implements BaseEvent {
  event_type = 'Unsubscribe Email Failed';

  constructor(
    public event_properties: UnsubscribeEmailFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UnsubscribeEmailPageViewed implements BaseEvent {
  event_type = 'Unsubscribe Email Page Viewed';
}

export class UnsubscribeEmailSuccess implements BaseEvent {
  event_type = 'Unsubscribe Email Success';

  constructor(
    public event_properties: UnsubscribeEmailSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UploadReceiptClicked implements BaseEvent {
  event_type = 'Upload Receipt Clicked';
}

export class UploadReceiptSuccess implements BaseEvent {
  event_type = 'Upload Receipt Success';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * App Update Error
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/App%20Update%20Error)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  appUpdateError(
    options?: EventOptions,
  ) {
    return this.track(new AppUpdateError(), options);
  }

  /**
   * App Update Success
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/App%20Update%20Success)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. newVersion)
   * @param options Amplitude event options.
   */
  appUpdateSuccess(
    properties: AppUpdateSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppUpdateSuccess(properties), options);
  }

  /**
   * Home Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Home%20Page%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  homePageViewed(
    options?: EventOptions,
  ) {
    return this.track(new HomePageViewed(), options);
  }

  /**
   * Landing Page CTA Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Landing%20Page%20CTA%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  landingPageCtaClicked(
    options?: EventOptions,
  ) {
    return this.track(new LandingPageCtaClicked(), options);
  }

  /**
   * Landing Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Landing%20Page%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  landingPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new LandingPageViewed(), options);
  }

  /**
   * Login Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Login%20Failed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  loginFailed(
    options?: EventOptions,
  ) {
    return this.track(new LoginFailed(), options);
  }

  /**
   * Login Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Login%20Page%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  loginPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new LoginPageViewed(), options);
  }

  /**
   * Login Success
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Login%20Success)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  loginSuccess(
    options?: EventOptions,
  ) {
    return this.track(new LoginSuccess(), options);
  }

  /**
   * Processed Receipt Image Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Processed%20Receipt%20Image%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. receiptId)
   * @param options Amplitude event options.
   */
  processedReceiptImageViewed(
    properties: ProcessedReceiptImageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProcessedReceiptImageViewed(properties), options);
  }

  /**
   * Receipt Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Receipt%20Page%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. receiptId)
   * @param options Amplitude event options.
   */
  receiptPageViewed(
    properties: ReceiptPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReceiptPageViewed(properties), options);
  }

  /**
   * Receipts Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Receipts%20Page%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  receiptsPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new ReceiptsPageViewed(), options);
  }

  /**
   * Register Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Register%20Failed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  registerFailed(
    options?: EventOptions,
  ) {
    return this.track(new RegisterFailed(), options);
  }

  /**
   * Register Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Register%20Page%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  registerPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new RegisterPageViewed(), options);
  }

  /**
   * Register Success
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Register%20Success)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  registerSuccess(
    options?: EventOptions,
  ) {
    return this.track(new RegisterSuccess(), options);
  }

  /**
   * Still Processing Receipt Image Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Still%20Processing%20Receipt%20Image%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. imageId)
   * @param options Amplitude event options.
   */
  stillProcessingReceiptImageViewed(
    properties: StillProcessingReceiptImageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new StillProcessingReceiptImageViewed(properties), options);
  }

  /**
   * Unable To Generate Name For Barcode
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Unable%20To%20Generate%20Name%20For%20Barcode)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. barcode)
   * @param options Amplitude event options.
   */
  unableToGenerateNameForBarcode(
    properties: UnableToGenerateNameForBarcodeProperties,
    options?: EventOptions,
  ) {
    return this.track(new UnableToGenerateNameForBarcode(properties), options);
  }

  /**
   * Unsubscribe Email Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Unsubscribe%20Email%20Failed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  unsubscribeEmailFailed(
    properties: UnsubscribeEmailFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UnsubscribeEmailFailed(properties), options);
  }

  /**
   * Unsubscribe Email Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Unsubscribe%20Email%20Page%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  unsubscribeEmailPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new UnsubscribeEmailPageViewed(), options);
  }

  /**
   * Unsubscribe Email Success
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Unsubscribe%20Email%20Success)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  unsubscribeEmailSuccess(
    properties: UnsubscribeEmailSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new UnsubscribeEmailSuccess(properties), options);
  }

  /**
   * Upload Receipt Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Upload%20Receipt%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  uploadReceiptClicked(
    options?: EventOptions,
  ) {
    return this.track(new UploadReceiptClicked(), options);
  }

  /**
   * Upload Receipt Success
   *
   * [View in Tracking Plan](https://data.amplitude.com/recibo/recibo/events/main/latest/Upload%20Receipt%20Success)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  uploadReceiptSuccess(
    options?: EventOptions,
  ) {
    return this.track(new UploadReceiptSuccess(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
