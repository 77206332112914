import { useLoaderData } from "react-router-dom";
import { getApiHost } from "../getApiHost";
import { ProcessedReceiptRow } from "../ProcessedReceiptRow";
import { ampli } from "../ampli";
import { useEffect } from "react";
import { StillProcessingReceiptRow } from "../StillProcessingReceiptRow";

export type ProcessedReceipt = {
  receiptId: string;
  vendorName: string;
  totalInCents: number;
  purchasedAt: Date;
  imageId: string;
};

export type StillProcessingReceipt = {
  imageId: string;
};

export const loader =
  (token: string | undefined) =>
  async ({ params, request }: { params: any; request: any }) => {
    const response = await fetch(`${getApiHost()}/receipts`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      const data: {
        processedReceipts: ProcessedReceipt[];
        stillProcessingReceipts: StillProcessingReceipt[];
      } = await response.json();
      return data;
    }
    return [];
  };

export function Receipts() {
  useEffect(() => {
    ampli.receiptsPageViewed();
  }, []);

  const receipts = useLoaderData() as {
    processedReceipts: ProcessedReceipt[];
    stillProcessingReceipts: StillProcessingReceipt[];
  };

  return (
    <div className="h-full">
      {!receipts.processedReceipts.length &&
      !receipts.stillProcessingReceipts.length ? (
        <div className="text-2xl p-5">
          Please upload your first receipt by clicking on the camera icon in the
          top-right.
        </div>
      ) : (
        <>
          <div className="text-center text-2xl p-4 font-medium">
            My Receipts
          </div>
          <div>
            {receipts.stillProcessingReceipts.map((receipt) => (
              <StillProcessingReceiptRow
                key={receipt.imageId}
                receipt={receipt}
              />
            ))}
          </div>
          <div>
            {receipts.processedReceipts.map((receipt) => (
              <ProcessedReceiptRow key={receipt.receiptId} receipt={receipt} />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
