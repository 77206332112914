import { Link } from "react-router-dom";
import { Button } from "./atoms/Button";

export function ErrorElement() {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="text-3xl pb-10">Something went wrong</div>
      <div className="text-xl">
        <Link to={"/home"}>
          <Button
            text="Back to homepage"
            size="lg"
            color="primary"
            type="button"
          />
        </Link>
      </div>
    </div>
  );
}
