import { LineItemByBarcodeData } from "./LineItemByBarcode";
import { Form, NavLink } from "react-router-dom";
import { Card, CardBody, CardHeader, Divider } from "@nextui-org/react";
import { Button } from "../atoms/Button";

export function LineItemByBarcodeRow({
  lineItem,
}: {
  lineItem: LineItemByBarcodeData;
}) {
  const date = new Date(lineItem.purchasedAt);
  const formatter = new Intl.DateTimeFormat("en-UK", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const formattedDate = formatter.format(date);

  return (
    <div className="my-4">
      <Card>
        <CardHeader className="text-sm justify-between">
          <NavLink
            to={`/receipt/${lineItem.receiptId}`}
            className="text-primary"
          >
            {lineItem.vendorName} @ {formattedDate}
          </NavLink>
        </CardHeader>
        <Divider />
        <CardBody className="flex-row justify-between">
          <div>
            {`${lineItem.quantity} X 
            ${(lineItem.unitPriceInCents / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "GBP",
            })} each`}
          </div>
          <div>
            <Form method="post" className="inline">
              <input
                type="hidden"
                name="lineItemId"
                value={lineItem.lineItemId}
              />
              <Button
                type="submit"
                name="intent"
                value="DELETE_BARCODE_BY_LINE_ITEM_ID"
                color={"default"}
                size={"sm"}
                text={"Remove barcode"}
              />
            </Form>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
