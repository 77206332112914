import { ActionFunctionArgs, Form } from "react-router-dom";
import { Button } from "../atoms/Button";
import { Input } from "../atoms/Input";
import { getApiHost } from "../getApiHost";
import { ampli } from "../ampli";
import { useEffect } from "react";

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const email = formData.get("email") as string;
  try {
    const response = await fetch(`${getApiHost()}/unsubscribe-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    const data: { message: string } = await response.json();
    if (response.status === 200) {
      ampli.unsubscribeEmailSuccess({
        email,
      });
      alert(data.message);
      return null;
    }
    if (response.status === 400) {
      ampli.unsubscribeEmailFailed({
        email,
        httpErrorCode: "400",
      });
      alert(data.message);
      return null;
    }
    ampli.unsubscribeEmailFailed({
      email,
      httpErrorCode: "500",
    });
    alert(data.message);
    return null;
  } catch (e) {
    alert("Something went wrong. Please try again later");
    console.error(e);
    ampli.unsubscribeEmailFailed({
      email,
      httpErrorCode: "500",
    });
    return null;
  }
}

export function UnsubscribeEmail() {
  useEffect(() => {
    ampli.unsubscribeEmailPageViewed();
  }, []);

  return (
    <div className="max-w-sm mx-auto text-center pt-5">
      <Form method="post" noValidate>
        <Input
          type="email"
          name="email"
          placeholder="Enter your email"
          className="pb-3"
        />
        <Button
          type={"submit"}
          color={"danger"}
          size={"md"}
          text={"Unsubscribe from all emails"}
        />
      </Form>
    </div>
  );
}
