import { useCallback, useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { getApiHost } from "../getApiHost";
import ScanbotSDK from "scanbot-web-sdk";
import { IBarcodeScannerHandle } from "scanbot-web-sdk/@types/interfaces/i-barcode-scanner-handle";
import { BarcodeResult } from "scanbot-web-sdk/@types/model/barcode/barcode-result";
import { ampli } from "../ampli";

export const loader =
  (token: string | undefined) =>
  async ({ params, request }: { params: any; request: any }) => {
    return { token, lineItemId: params.lineItemId };
  };

export function LinkBarcodeByLineItemId() {
  const { token, lineItemId } = useLoaderData() as {
    token: string | undefined;
    lineItemId: string;
  };

  const navigate = useNavigate();

  const [sdk, setSdk] = useState<ScanbotSDK | undefined>(undefined);

  const [networkRequestState, setNetworkRequestState] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");

  const [barcode, setBarcode] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function initSdk() {
      const sdk = await ScanbotSDK.initialize({
        licenseKey: "",
      });
      setSdk(sdk);
    }
    if (networkRequestState === "idle") {
      initSdk();
    }
  }, [networkRequestState]);

  const makeApiCall = useCallback(
    async (barcode: string) => {
      try {
        setNetworkRequestState("loading");
        const response = await fetch(
          `${getApiHost()}/link_barcode_by_line_item_id`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              lineItemId,
              barcode,
            }),
          }
        );
        if (response.status === 200) {
          console.log("Successfully scanned barcode");
          setNetworkRequestState("success");
          navigate(`/line-item-by-barcode/${barcode}`);
        } else {
          setNetworkRequestState("error");
        }
      } catch (e) {
        console.error(e);
        setNetworkRequestState("error");
      }
    },
    [lineItemId, navigate, token]
  );

  useEffect(() => {
    let scanner: IBarcodeScannerHandle | undefined = undefined;
    async function scanBarcode() {
      scanner = await sdk?.createBarcodeScanner({
        containerId: "scanner",
        onBarcodesDetected: (result: BarcodeResult) => {
          scanner?.dispose();
          const text = result.barcodes[0].text;
          setBarcode(text);
          makeApiCall(text);
        },
      });
    }
    scanBarcode();
    return () => {
      scanner?.dispose();
    };
  }, [token, navigate, sdk, makeApiCall]);

  switch (networkRequestState) {
    case "idle":
      return (
        <div
          id="scanner"
          style={{
            height: "100dvh",
            width: "100dvw",
          }}
        ></div>
      );
    case "loading":
      return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
        </div>
      );
    case "error":
      if (barcode) {
        ampli.unableToGenerateNameForBarcode({
          barcode,
        });
      }
      return (
        <div className="h-full p-5 mt-7">
          <div className="text-center text-3xl pt-4">Something went wrong</div>
          <div className="text-center text-2xl p-4">
            Please try a different barcode until we fix this.
          </div>
          <div className="text-center">
            <button
              className="bg-[#2F75C1] text-white rounded text-xl py-2 px-4"
              onClick={() => navigate("/home")}
            >
              Back to home
            </button>
          </div>
        </div>
      );
    case "success":
      return null;
  }
}
