import {
  ChartBarIcon,
  CloudIcon,
  LightBulbIcon,
} from "@heroicons/react/24/outline";
import { Card, CardBody, CardHeader } from "@nextui-org/card";
import { Divider } from "@nextui-org/react";

export function Features() {
  return (
    <div>
      <div className="text-2xl text-center pb-5 font-bold">Features</div>
      <Card className="mb-5">
        <CardHeader className="font-semibold flex-row">
          <LightBulbIcon className="size-6 inline-block mr-2" /> Grocery
          Spending Insights
        </CardHeader>
        <Divider />
        <CardBody>Pinpoint the items that eat up your budget</CardBody>
      </Card>
      <Card className="mb-5">
        <CardHeader className="font-semibold flex-row">
          <ChartBarIcon className="size-6 inline-block mr-2" />
          Personal Price History
        </CardHeader>
        <Divider />
        <CardBody>
          Scan and instantly recall what you spent on any item
        </CardBody>
      </Card>
      <Card className="mb-5">
        <CardHeader className="font-semibold flex-row">
          <CloudIcon className="size-6 inline-block mr-2" />
          Digital Receipts
        </CardHeader>
        <Divider />
        <CardBody>
          Ditch the paper! Scan and store all receipts in one app, accessible
          anytime
        </CardBody>
      </Card>
    </div>
  );
}
