import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./Auth";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { useEffect } from "react";

const MAIN_CONTENT_ID = "main-content";

export function AuthRoot() {
  const { user } = useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    document.getElementById(MAIN_CONTENT_ID)?.scrollTo({
      behavior: "smooth",
      left: 0,
      top: 0,
    });
  }, [pathname]);

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="flex flex-col h-full">
      <div className="">
        <Header showUploadReceipt={true} showSearchByBarcode={true} />
      </div>
      <div id={MAIN_CONTENT_ID} className="flex-1 overflow-auto pt-2">
        <Outlet />
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );
}
