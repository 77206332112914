import { Link, NavLink, useLoaderData } from "react-router-dom";
import { getApiHost } from "../getApiHost";
import { Card, CardBody, CardHeader } from "@nextui-org/react";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
export const loader = (token: string | undefined) => async () => {
  const response = await fetch(`${getApiHost()}/morrisons_price_alerts`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status === 200) {
    const { alertsWithLatestPrice } = await response.json();
    return alertsWithLatestPrice;
  }
  return [];
};

export function PriceAlerts() {
  const priceAlerts = useLoaderData() as {
    productLink: string;
    priceAlertId: string;
    latestPrice?: {
      totalPriceInCentsWithPromotion: number | null;
      totalPriceInCentsWithoutPromotion: number;
      promotionDescription: string | null;
    };
  }[];

  const sortedPriceAlerts = priceAlerts.sort((a, b) => {
    return (
      (b.latestPrice?.totalPriceInCentsWithPromotion ? 1 : 0) -
      (a.latestPrice?.totalPriceInCentsWithPromotion ? 1 : 0)
    );
  });

  return (
    <div className="h-full">
      <div className="text-center text-2xl p-4 font-medium">Price Alerts</div>
      <div>
        {sortedPriceAlerts.map((alert) => (
          <div className="my-4">
            <Card>
              {alert.latestPrice && (
                <CardHeader>
                  {alert.latestPrice.promotionDescription &&
                  alert.latestPrice.totalPriceInCentsWithPromotion ? (
                    <div className="text-success">
                      {alert.latestPrice.totalPriceInCentsWithoutPromotion !==
                        alert.latestPrice.totalPriceInCentsWithPromotion && (
                        <span className="line-through">
                          {(
                            alert.latestPrice
                              .totalPriceInCentsWithoutPromotion / 100
                          ).toLocaleString("en-UK", {
                            style: "currency",
                            currency: "GBP",
                          })}
                        </span>
                      )}
                      {alert.latestPrice.totalPriceInCentsWithoutPromotion !==
                        alert.latestPrice.totalPriceInCentsWithPromotion && " "}
                      {(
                        alert.latestPrice.totalPriceInCentsWithPromotion / 100
                      ).toLocaleString("en-UK", {
                        style: "currency",
                        currency: "GBP",
                      })}
                      {" | "}
                      {alert.latestPrice.promotionDescription}
                    </div>
                  ) : (
                    <div className="text-danger">
                      {(
                        alert.latestPrice.totalPriceInCentsWithoutPromotion /
                        100
                      ).toLocaleString("en-UK", {
                        style: "currency",
                        currency: "GBP",
                      })}
                    </div>
                  )}
                </CardHeader>
              )}
              <CardBody className="text-primary flex-row justify-between items-center">
                <NavLink to={`/price-alert/${alert.priceAlertId}`}>
                  {alert.productLink.split("/")[4].replace(/-/g, " ")}
                </NavLink>
                <Link to={alert.productLink} target="_blank">
                  <ArrowUpRightIcon className="size-6 inline-block ml-2" />
                </Link>
              </CardBody>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}
