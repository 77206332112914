import { Card, CardBody } from "@nextui-org/react";
import { Input } from "../atoms/Input";
import { NavLink, useLoaderData, useSearchParams } from "react-router-dom";
import { getApiHost } from "../getApiHost";

export type SearchResult = {
  lineItemsWithoutBarcode: { lineItemName: string }[];
  lineItemsWithBarcode: {
    lineItemDescriptiveName: string[];
    barcode: string;
  }[];
};

export const loader =
  (token: string | undefined) =>
  async ({ params, request }: { params: any; request: any }) => {
    const query = new URL(request.url).searchParams.get("query");
    if (!token || !query)
      return {
        lineItemsWithoutBarcode: [],
        lineItemsWithBarcode: [],
      };
    const response = await fetch(`${getApiHost()}/search?query=${query}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      const data: SearchResult = await response.json();
      return { ...data };
    }

    return {
      lineItemsWithoutBarcode: [],
      lineItemsWithBarcode: [],
    };
  };

export function Search() {
  const { lineItemsWithoutBarcode, lineItemsWithBarcode } =
    useLoaderData() as SearchResult;

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="h-full">
      <div>
        <Input
          type="text"
          name="search"
          placeholder="Search"
          className="pb-2"
          defaultValue={searchParams.get("query") || undefined}
          onValueChange={(newValue) => {
            setSearchParams({
              query: newValue,
            });
          }}
        />
      </div>
      <div className="my-4">
        {lineItemsWithBarcode.map((lineItemWithBarcode) => {
          return (
            <div className="my-4">
              <Card>
                <CardBody>
                  <NavLink
                    to={`/line-item-by-barcode/${lineItemWithBarcode.barcode}`}
                    className="text-primary"
                  >
                    {lineItemWithBarcode.lineItemDescriptiveName}
                  </NavLink>
                </CardBody>
              </Card>
            </div>
          );
        })}
        {lineItemsWithoutBarcode.map((lineItemWithoutBarcode) => {
          return (
            <div className="my-4">
              <Card>
                <CardBody>
                  <NavLink
                    to={`/line-item-by-name/${encodeURIComponent(
                      lineItemWithoutBarcode.lineItemName
                    )}`}
                    className="text-primary"
                  >
                    {lineItemWithoutBarcode.lineItemName}
                  </NavLink>
                </CardBody>
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
}
